import { clone } from 'lodash'
import def from './default'

var number = clone(def)

number.isRight = true

number.filterPredicate = function defaultFilter(rowval, filter) {
  return number.compare(rowval, filter) === 0
}

number.compare = function compareNumbers(x, y) {
  function cook(d) {
    return d.indexOf('.') >= 0 ? parseFloat(d) : parseInt(d)
  }

  x = typeof x === 'number' ? x : cook(x)
  y = typeof y === 'number' ? y : cook(y)
  return x < y ? -1 : x > y ? 1 : 0
}

export default number

import { find, isEmpty, findIndex, drop } from 'lodash'

const PAGE_DATA_LIMIT = 5

export default {
  state: () => ({
    pageInfoTmp: [],
    pageInfo: []
  }),
  getters: {
    getPageData: state => pageId => {
      const searchInfo = find(state.pageInfo, { pageId: pageId })

      return searchInfo === undefined ? searchInfo : searchInfo.pageData
    }
  },
  actions: {
    PAGE_DATA_ADD({ route, data }) {
      if (isEmpty(data) || isEmpty(route.name)) {
        return
      }

      const searchIdx = findIndex(this.pageInfoTmp, { pageId: route.name })

      if (searchIdx >= 0) {
        this.pageInfoTmp[searchIdx] = { pageId: route.name, pageData: data }
      } else {
        this.pageInfoTmp.push({ pageId: route.name, pageData: data })
      }
    },
    PAGE_DATA_UPDATE() {
      const tmpCnt = this.pageInfoTmp.length

      if (tmpCnt > 0) {
        const dropCnt = this.pageInfo.length + tmpCnt - PAGE_DATA_LIMIT

        if (dropCnt > 0) {
          this.pageInfo = drop(this.pageInfo, dropCnt)
        }
        this.pageInfo = this.pageInfo.concat(this.pageInfoTmp)
        this.pageInfoTmp = []
      }
    }
  }
}

export default {
  state: () => ({
    asideActive: false,
    mode: '',
    popupResult: [], // 팝업 close 시 전달할 결과 리스트
    popupTitle: [], // 동적 팝업 타이틀 변경 시 사용
    popupBack: [] // 팝업 back 버튼 사용 시 사용
  }),
  getters: {
    // 팝업 타이틀 값
    getTitle: state => modalName => {
      return state.popupTitle[modalName]
    },
    // 팝업 back버튼 값
    getClickBack: state => modalName => {
      return state.popupBack[modalName]
    }
  },
  actions: {
    // 팝업 결과 전달
    closeResult({ modalName, result }) {
      // console.log('[store] closeResult', modalName, result)
      this.popupResult[modalName] = result
    },
    // 팝업 결과 클리어
    clearResult(modalName) {
      this.popupResult[modalName] = ''
    },
    // 팝업 타이틀 변경
    changeTitle({ modalName, title }) {
      console.log('[store] changeTitle', modalName, title)
      this.popupTitle[modalName] = title
    }
  }
}

import { format, parse, isValid, compareAsc, addDays, addMonths, lastDayOfMonth } from 'date-fns'
import { clone, isEmpty } from 'lodash'
import def from './default'

var date = clone(def)

date.isRight = true
/**
 * 입력값1 과 입력과2를 비교하여 결과를 리턴한다
 * @param x 입력값1 날짜
 * @param y 입력값2 날짜
 * @param column {inputFormat: } 입력값의 포멧
 * @return boolean true: 같음 false: 다름
 */
date.compare = function compare(x, y, column) {
  function cook(d) {
    if (column && column.inputFormat) {
      return parse(d + '', '' + column.inputFormat, new Date())
    } else {
      return d
    }
  }
  x = cook(x)
  y = cook(y)
  if (!isValid(x)) {
    return -1
  }
  if (!isValid(y)) {
    return 1
  }
  return compareAsc(x, y)
}
/**
 * 날짜를 inputFormat으로 받아 outputFormat으로 포멧팅하여 결과를 리턴한다
 * @param v 입력값 날짜
 * @param column {inputFormat: , outputFormat:} 입력값의 포멧
 * @return date
 */
date.format = function (v, column) {
  // convert to date
  if (column.inputFormat === 'YYYYMM') {
    v = v + '01'
  }
  var date = parse(v, column.inputFormat, new Date())
  return format(date, column.outputFormat)
}
/**
 * 날짜를 받아 outputFormat으로 포멧팅하여 결과를 리턴한다
 * @param v 입력값 날짜
 * @param outputFormat 출력값 포멧
 * @return date
 */
date.formatDate = function (v, outputFormat) {
  return format(v, outputFormat)
}
/**
 * 날짜를 받아 더해지는 일수 결과를 리턴한다
 * @param v 입력값 날짜
 * @param a 더해지는 일수
 * @return date
 */
date.addDay = function (v, a) {
  return addDays(v, a)
}
/**
 * 날짜를 받아 더해지는 월수 결과를 리턴한다
 * @param v 입력값 날짜
 * @param a 더해지는 월수
 * @return date
 */
date.addMonth = function (v, a) {
  return addMonths(v, a)
}
/**
 * 날짜를 그 달의 마지막 날을 리턴한다
 * @param v 입력값 날짜
 * @return date
 */
date.lastDayOfMonth = function (v) {
  return lastDayOfMonth(v)
}
/**
 * 문자열을 받아 Date로 변환 후 리턴한다
 * @param strDate 입력값 문자열
 * @return date
 */
date.convStrToDate = function (strDate) {
  const dt = isEmpty(strDate) ? '' : strDate.replace(/\D/g, '')
  let newDate

  switch (dt.length) {
    case 8:
      newDate = new Date(dt.substr(0, 4), dt.substr(4, 2) - 1, dt.substr(6, 2))
      break
    case 6:
      newDate = new Date(dt.substr(0, 4), dt.substr(4, 2) - 1)
      break
    case 4:
      newDate = new Date(dt.substr(0, 4))
      break
    default:
      newDate = new Date()
      break
  }
  return newDate
}

export default date

import axios from 'axios'
import { isEmpty, filter, find } from 'lodash'
// import { event } from 'vue-gtag'

export default {
  state: () => ({
    isAuth: false,
    isLogin: false,
    startDate: '',
    userData: {},
    befRoute: '',
    befRouteQuery: '',
    commonCode: {},
    errcode: '',
    csrf: '',
    naverMapClose: true,
    refferId: '',
    isMessage: false,
    messageCode: {},
    axiosKey: '',
    regInfo: {
      regGnb: '1', // 1: 로그인, 2: 회원가입
      termReg: false, // 동의여부
      idDrawal: false // 탈회여부
    },
    isMarketing: false,
    imgIcons: [
      { id: '00', img: 'profile00', extension: 'png' }, // 프로필업로드 선택했으나 이미지 미업로드시 기본이미지
      { id: '01', img: 'profile01', extension: 'png' },
      { id: '02', img: 'profile02', extension: 'png' },
      { id: '03', img: 'profile03', extension: 'png' },
      { id: '04', img: 'profile04', extension: 'png' },
      { id: '05', img: 'profile05', extension: 'png' },
      { id: '06', img: 'profile06', extension: 'png' },
      { id: '07', img: 'profile07', extension: 'png' },
      { id: '08', img: 'profile08', extension: 'png' },
      { id: '09', img: 'profile09', extension: 'png' },
      { id: '10', img: 'profile10', extension: 'png' },
      { id: '99', img: 'profile_all', extension: 'svg' }
    ],
    analysis: {
      key: '',
      historyNumber: 2
    }
  }),
  getters: {
    getUserDataJson: state => () => {
      if (isEmpty(state.userData)) {
        return ''
      }
      return JSON.stringify(state.userData)
    },
    getCommonCode: state => id => {
      return filter(state.commonCode, function (item) {
        return item.cmnCdTypeCd === id
      })
    },
    getCommonMsg:
      state =>
      (code, ...msgArg) => {
        const msgArray = filter(state.commonCode, function (item) {
          return item.cmnCdTypeCd === 'FA0009'
        })
        console.log('[store] code msgArray', code, msgArray)
        const findMsg = find(msgArray, function (item) {
          return item.value === code
        })
        console.log('[store] findMsg', findMsg)
        if (isEmpty(findMsg)) {
          return ''
        } else {
          let msgText = findMsg.text
          for (let msgIdx = 0; msgIdx < msgArg.length; msgIdx++) {
            msgText = msgText.replace('&', msgArg[msgIdx])
          }
          return msgText
        }
      },
    getUserIconSrc: state => (imgCd, imgCut) => {
      const defaultUrl = imgCd !== '99' ? '/assets/main/' : '/assets/common/'
      const defaultUserIcon = 'profile00.png'
      const imgList = state.imgIcons
      if (isEmpty(imgList)) {
        return defaultUrl + defaultUserIcon
      } else if (!isEmpty(imgCd)) {
        const userIconObj = find(imgList, { id: imgCd })
        return defaultUrl + userIconObj.img + '.' + userIconObj.extension
      } else if (!isEmpty(imgCut)) {
        return imgCut
      } else {
        return defaultUrl + defaultUserIcon
      }
    }
  },
  actions: {
    LOGIN({ snsType, accessToken, referalId, code }) {
      console.log('[LOGIN!!!]', snsType, accessToken, referalId)
      const _self = this
      return new Promise(function (resolve, reject) {
        const apiPath = '/GW/stoplay/login/oauth'
        axios
          .post(apiPath, {
            snsType: snsType,
            accessToken: accessToken,
            code: code,
            referalId: referalId
          })
          .then(result => {
            const res = result.data
            if (res.result.code === 'SUCCESS') {
              console.log('res', res)
              // 성공로직
              _self.errcode = ''
              _self.refferId = ''
              if (res.data.firstRegistYn) {
                console.log('최초가입')
              } else {
                _self.AUTH_STATE_UPDATE(true)
              }
              _self.USER_DATA_UPDATE(res.data)
              _self.IS_LOGIN_UPDATE(false)
              // event('login', { event_name: 'login', value: userId })
              resolve(res)
            } else {
              // 실패로직
              resolve(res)
            }
          })
          .then(err => {
            reject(err)
          })
      })
    },
    LOGOUT() {
      const _self = this
      return new Promise(function (resolve, reject) {
        axios
          .post('/GW/C1/logout')
          .then(result => {
            // event('logout', { event_name: 'logout', value: state.userData.userId })
            _self.startDate = ''
            _self.AUTH_STATE_UPDATE(false)
            _self.USER_DATA_UPDATE({})
            resolve(result)
          })
          .then(err => {
            reject(err)
          })
      })
    },
    CHECK_STATUS(to) {
      const _self = this
      console.log('[auth]', to)
      return new Promise(function (resolve, reject) {
        if (_self.errcode === 'network') {
          resolve(true)
        } else {
          console.log('CHECK_STATUS state.errcode 2', _self.errcode)
          axios
            .post('/GW/C1/status', {}, { loadingOff: true })
            .then(result => {
              // console.log('[status]', result.headers._csrf)
              const res = result.data
              // state.csrf = result.headers._csrf
              if (res.result.code === 'SUCCESS') {
                if (isEmpty(res.data.userId)) {
                  _self.AUTH_STATE_UPDATE(false)
                  _self.USER_DATA_UPDATE({})
                } else {
                  _self.AUTH_STATE_UPDATE(true)
                  // _self.USER_DATA_UPDATE(res.data)
                  // TODO 문의 필요 : 새로고침 시 데이터 셋팅안됨
                  // _self.GET_USER_DATA(res.data.userId)
                }
                resolve(true)
              } else {
                _self.AUTH_STATE_UPDATE(false)
                _self.USER_DATA_UPDATE({})
                resolve(false)
              }
            })
            .then(err => {
              reject(err)
            })
        }
      })
    },
    GET_USER_DATA() {
      const _self = this
      const userData = _self.userData
      return new Promise(function (resolve, reject) {
        if (_self.errcode === 'network') {
          resolve(true)
        } else {
          axios
            .post('/GW/router/STOPLAY/CMN/CmnMember/detail', { isPointSel: false }, { loadingOff: true })
            .then(result => {
              // console.log('[status]', result.headers._csrf)
              const res = result.data
              // state.csrf = result.headers._csrf
              if (res.result.code === 'SUCCESS') {
                userData.userId = res.data.userId
                userData.nickNm = res.data.nickNm
                userData.registerDefaultLevel = res.data.registerDefaultLevel
                if (!isEmpty(res.data)) {
                  const imgObj = {
                    profileImage: res.data.profileImage,
                    profileImageCd: res.data.profileImageCd,
                    profileImageCut: res.data.profileImageCut,
                    profileImageUrl: res.data.profileImageUrl,
                    emailAddr: res.data.emailAddr,
                    snsType: res.data.snsType
                  }
                  const updateData = { ...userData, ...imgObj }
                  // console.log('[updateData]', updateData)
                  _self.USER_DATA_UPDATE(updateData)
                }
                resolve(true)
              } else {
                resolve(false)
              }
            })
            .then(err => {
              reject(err)
            })
        }
      })
    },
    AUTH_STATE_UPDATE(isAuth) {
      this.isAuth = isAuth
    },
    USER_DATA_UPDATE(userData) {
      // console.log('[store] userData', userData)
      this.userData = userData
    },
    USER_IMG_UPDATE(imgData) {
      // console.log('[store] imgData', imgData)
      // this.userData = userData
      this.userData.profileImage = imgData.profileImage
      this.userData.profileImageCd = imgData.profileImageCd
      this.userData.profileImageCut = imgData.profileImageCut
      this.userData.profileImageUrl = imgData.profileImageUrl
    },
    NEXT_ROUTE_UPDATE(next) {
      this.befRoute = next
    },
    CODE_DATA_UPDATE(commonCode) {
      this.commonCode = commonCode
    },
    MSG_DATA_UPDATE(messageCode) {
      this.messageCode = messageCode
    },
    COA_DATA_UPDATE(coaCode) {
      this.coaCode = coaCode
    },
    EMP_DATA_UPDATE(empCode) {
      this.empCode = empCode
    },
    DPT_DATA_UPDATE(dptCode) {
      this.dptCode = dptCode
    },
    forbiddenValidation(text) {
      // param : text - 검사할 내용
      const forbiddenApi = '/GW/router/STOPLAY/validator/forbiddenString'
      let param = {
        checkData: text
      }
      return new Promise(function (resolve, reject) {
        axios
          .post(forbiddenApi, param, { loadingOff: true })
          .then(res => {
            const result = res.data.result
            if (result.code === 'SUCCESS') {
              // 체크 확인
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .then(err => {
            reject(err)
          })
      })
    },
    IS_LOGIN_UPDATE(isLogin) {
      this.isLogin = isLogin
    }
  },
  persist: {
    paths: ['isAuth', 'refferId', 'analysis', 'isLogin', 'regInfo', 'isMarketing'],
    storage: sessionStorage,
    beforeRestore: ctx => {
      console.log(`store start '${ctx.store.$id}'`)
    },
    afterRestore: ctx => {
      console.log(`store complete '${ctx.store.$id}'`)
    }
  }
}

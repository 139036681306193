<template>
  <iframe :id="id" :type="type" :width="width" :height="height" :src="urlSrc" :frameborder="frameborder"> </iframe>
</template>

<script>
import { isEmpty } from 'lodash'
export default {
  name: 'UiYoutube',
  components: {},
  mixins: [],
  props: {
    id: {
      type: String,
      default: 'defaultIframeId'
    },
    type: {
      type: String,
      default: 'text/html'
    },
    url: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '430'
    },
    height: {
      type: String,
      default: '300'
    },
    frameborder: {
      type: String,
      default: '300'
    },
    autoplay: {
      type: String,
      default: '1'
    },
    origin: {
      type: String,
      default: 'https://dobu.kr'
    }
  },
  data() {
    return {
      embedUrl: 'https://www.youtube.com/embed/'
    }
  },
  computed: {
    urlSrc() {
      const id = this.$novaCom.filteredUrlId(this.url)
      const rtnUrl = this.embedUrl + id + '?autoplay=' + this.autoplay + '&origin=' + this.origin
      console.log('rtnUrl')
      return rtnUrl
    }
  },
  watch: {},
  mounted() {
    // console.log('UICommonModal mounted')
  },
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {
    checkUrl() {
      const id = this.$novaCom.filteredUrlId(this.url)

      const youtubeApi = '/GW/stoplay/proxy/send'
      const _params = {
        snsType: 'GGL',
        subUrl: '/youtube/v3/videos?id=' + id + '&key=' + import.meta.env.VUE_APP_GOOGLE_AUTH_JS_KEY,
        accessToken: '',
        proxyHeader: '{}',
        proxyBody: '{}',
        method: 'GET'
      }
      this.$http.post(youtubeApi, _params).then(res => {
        // console.log('youtube', res)
        const result = res.data.items
        if (isEmpty(result)) {
          // true
        }
      })
    }
  }
}
</script>
<style lang="scss"></style>

<template>
  <div class="full-size-img-wrap">
    <v-img contain class="full-size-img" :src="imgSrc" @click="closeCommonModal()" ></v-img>
  </div>
</template>

<script>
export default {
  name: 'UiFullSizeImgModal',
  mixins: [],
  props: {
    activeBtnClose: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    imgSrc: {
      type: String,
      default: ''
    },
    modalName: {
      type: String
    }
  },
  data() {
    return {}
  },
  created() {
    // console.log('UICommonModal created')
  },
  mounted() {
    // console.log('UICommonModal mounted')
  },
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {
    closeCommonModal() {
      this.$modalHide(this.modalName, {})
    }
  }
}
</script>

import { clone } from 'lodash'
import number from './number'

var decimal = clone(number)
/**
 * 버튼 권한 존재여부 체크
 * @param v 입력값
 * @param l 소수점 자리수
 * @return decimal 소수점 l인 값
 */
decimal.format = function formatDecimal(v, l) {
  const pow = Math.pow(10, l)
  return parseFloat(Math.round(v * pow) / pow).toFixed(l)
}

export default decimal

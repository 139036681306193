import axios from 'axios'
import { forEach, find, findIndex, cloneDeep, filter, isEmpty } from 'lodash'

const guideMenu = [
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G00000',
    menuKoNm: '샘플',
    name: null,
    parMenuId: 'T00000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 0,
    tierLvlNo: '1',
    url: '',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10000',
    menuKoNm: 'Component',
    name: null,
    parMenuId: 'G00000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91000,
    tierLvlNo: '2',
    url: '',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G20000',
    menuKoNm: '공통기능',
    name: null,
    parMenuId: 'G00000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 92000,
    tierLvlNo: '2',
    url: '',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G30000',
    menuKoNm: '외부모듈연계',
    name: null,
    parMenuId: 'G00000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91000,
    tierLvlNo: '2',
    url: '',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10001',
    menuKoNm: 'Icon',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91001,
    tierLvlNo: '3',
    url: '/com/guide/component/icon',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10002',
    menuKoNm: 'Chart',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91002,
    tierLvlNo: '3',
    url: '/com/guide/component/chart',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10003',
    menuKoNm: 'Slide',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91003,
    tierLvlNo: '3',
    url: '/com/guide/component/slide',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10004',
    menuKoNm: 'Grid',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91004,
    tierLvlNo: '3',
    url: '/com/guide/component/grid',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10005',
    menuKoNm: 'Modal',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91005,
    tierLvlNo: '3',
    url: '/com/guide/component/modal',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10006',
    menuKoNm: 'DatePicker',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91006,
    tierLvlNo: '3',
    url: '/com/guide/component/datePicker',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10007',
    menuKoNm: 'Pagination',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91007,
    tierLvlNo: '3',
    url: '/com/guide/component/pagination',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10008',
    menuKoNm: 'Select',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91008,
    tierLvlNo: '3',
    url: '/com/guide/component/select',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10009',
    menuKoNm: 'Tabs',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91009,
    tierLvlNo: '3',
    url: '/com/guide/component/tabs',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10010',
    menuKoNm: 'Button',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91010,
    tierLvlNo: '3',
    url: '/com/guide/component/button',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10011',
    menuKoNm: 'Input',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91011,
    tierLvlNo: '3',
    url: '/com/guide/component/input',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10012',
    menuKoNm: 'TextArea',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91012,
    tierLvlNo: '3',
    url: '/com/guide/component/textArea',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10013',
    menuKoNm: 'FileUpload',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91013,
    tierLvlNo: '3',
    url: '/com/guide/component/fileUpload',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G10012',
    menuKoNm: 'naverMap',
    name: null,
    parMenuId: 'G10000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91015,
    tierLvlNo: '3',
    url: '/com/guide/component/naverMap',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G20001',
    menuKoNm: 'Plugins',
    name: null,
    parMenuId: 'G20000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 92001,
    tierLvlNo: '3',
    url: '/com/guide/plugins/main',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G20002',
    menuKoNm: 'Server SPA Set',
    name: null,
    parMenuId: 'G20000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 92002,
    tierLvlNo: '3',
    url: '/com/guide/plugins/webconfig',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G20003',
    menuKoNm: 'setup()',
    name: null,
    parMenuId: 'G20000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 92003,
    tierLvlNo: '3',
    url: '/com/guide/plugins/setup',
    useYn: null
  },
  {
    eventList: [],
    id: null,
    leafMenuYn: 'N',
    menuDesc: null,
    menuEnNm: null,
    menuId: 'G30001',
    menuKoNm: '주소',
    name: null,
    parMenuId: 'G30000',
    roleId: null,
    scrsc: '',
    sendClient: true,
    sortOrd: 91014,
    tierLvlNo: '3',
    url: '/com/guide/component/juso',
    useYn: null
  }
]
function makeTree(listData, routeType) {
  console.log('[menu] makeTree', routeType)
  if (listData === null || listData.length < 1) {
    return {
      menus: []
    }
  }
  const treeObj = {
    menus: []
  }

  forEach(listData, function (val) {
    if (!(val.isMenu !== undefined && val.isMenu === false)) {
      if (val.menuId === 'T00000') {
        val.isOpen = false
        treeObj.menus.push(val)
      } else {
        const parentMenu = find(listData, { menuId: val.parMenuId })

        if (parentMenu !== undefined) {
          if (parentMenu.menus === undefined) {
            parentMenu.menus = []
          }
          val.isOpen = false
          parentMenu.menus.push(val)
        }
      }
    }
  })

  return treeObj
}

export default {
  state: () => ({
    topTree: {},
    topList: [],
    sideTree: {},
    sideList: [],
    isLoaded: false,
    routeType: 'p',
    sideMenuDisplay: false,
    currentMenuPath: 'S90000T01',
    currentMenuIdx: 0,
    currentMenuItem: {},
    menuMaxCnt: 0,
    openMenuCnt: 0,
    eventBreak: [],
    roleType: '00',
    sideHiddenShow: false,
    tabs: [],
    tabName: 'one'
  }),
  getters: {
    getItem: state => id => {
      return find(state.sideList, { menuId: id })
    },
    getItemByPath: state => path => {
      return find(state.sideList, { url: path })
    },
    getItemByScrsc: state => scrsc => {
      return find(state.sideList, { scrsc: scrsc })
    },
    getItemByChId: state => cdId => {
      return find(state.sideList, function (item) {
        if (isEmpty(item.url)) {
          return false
        } else {
          const urlArray = item.url.split('/')
          return urlArray[urlArray.length - 1] === cdId
        }
      })
    },
    getItemByTopTree: state => path => {
      let itemInfo = find(state.sideList, { url: path })
      if (!isEmpty(itemInfo)) {
        const rootValue = true
        while (rootValue) {
          if (itemInfo.parMenuId === 'T00000') {
            return itemInfo
          } else {
            itemInfo = find(state.sideList, { menuId: itemInfo.parMenuId })
          }
        }
      } else {
        return {}
      }
    },
    isOpened: state => id => {
      const findOpened = find(state.sideList, { menuId: id })
      return findOpened.isOpen
    }
  },
  actions: {
    MENU_SYNC() {
      const _self = this
      return new Promise(function (resolve, reject) {
        if (_self.isLoaded) {
          resolve(true)
          return
        }
        const menuUrl = '/GW/router/MANAGEMENT/menu/selectMenuListByRole'
        axios
          .post(menuUrl)
          .then(async res => {
            const result = res.data.result
            if (result.code === 'SUCCESS') {
              // console.log('[menu] success data', res.data.data)
              const menuList = [].concat(res.data.data.list, guideMenu)
              await _self.MENU_DATA_UPDATE(menuList)
              _self.sideTree = _self.topTree.menus[0].menus[0]
              console.log('[menu] state.sideTree', _self.sideTree)
              resolve(true)
            } else {
              // console.log('[menu] fail data', res)
              _self.isLoaded = false
              resolve(false)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    MENU_DATA_UPDATE(data) {
      // console.log('[menu] MENU_DATA_UPDATE', data)
      this.sideList = cloneDeep(data)
      this.topTree = makeTree(this.sideList, this.routeType)
      this.isLoaded = true
      // console.log('[menu] state.sideTree', state.sideTree)
      const eventBreak = filter(this.sideList, function (menu) {
        return !isEmpty(menu.eventList)
      })
      // console.log('[eventBreak]', eventBreak)
      for (let idx = 0; idx < eventBreak.length; idx++) {
        for (let evtIdx = 0; evtIdx < eventBreak[idx].eventList.length; evtIdx++) {
          const eventObj = {
            path: eventBreak[idx].url,
            url: eventBreak[idx].eventList[evtIdx].url,
            roleType: eventBreak[idx].eventList[evtIdx].evntTypeCd
          }
          this.eventBreak.push(eventObj)
        }
      }
      // console.log('[state.eventBreak]', state.eventBreak)
    },
    MENU_DATA_CHNAGE(menuId) {
      // console.log('[menu] menuId MENU_DATA_CHNAGE', menuId)
      const findMenuIndex = findIndex(this.topTree.menus[0].menus, function (menu) {
        return menu.menuId === menuId
      })
      // console.log('[menu] findMenuIndex MENU_DATA_CHNAGE', findMenuIndex)
      if (this.currentMenuIdx === findMenuIndex) return
      this.currentMenuIdx = findMenuIndex
      this.sideTree = this.topTree.menus[0].menus[findMenuIndex]
      // console.log('[menu] state.topTree.menus[0].menus', state.topTree.menus[0].menus[findMenuIndex])
      // console.log('[menu] state.sideTree', state.sideTree)
    },
    MENU_TOGGLE(state, data) {
      const findIdx = findIndex(state.sideList, { menuId: data.menuId })
      if (!data.isOpen) {
        // const findList = filter(state.sideList, { parMenuId: data.parMenuId })
        // if (findList !== undefined && findList.length > 0) {
        //   forEach(findList, function (val, idx) {
        //     val.isOpen = false
        //   })
        // }
      }
      data.isOpen = !data.isOpen
      state.sideList[findIdx] = data
    },
    SET_ROUTE_TYPE(data) {
      this.routeType = data
    }
  }
}

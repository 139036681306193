<template>
  <div v-dompurify-html="content" class="txt-normal"></div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

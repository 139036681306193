<template>
  <h3 v-dompurify-html="text" class="txt-bold"></h3>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

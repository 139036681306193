// 로딩바의 로딩 카운트를 저장하는 스토어
export default {
  state: () => ({
    loadingCnt: 0,
    instance: undefined,
    size: { height: 0, width: 0 }
  }),
  getters: {},
  actions: {
    // 로딩카운트 증가
    increment(payload) {
      this.loadingCnt = this.loadingCnt + payload
    },
    // 로딩카운트 감소
    decrement(payload) {
      // 로딩카운트를 지정하지 않으면 로딩 초기화
      if (payload === undefined) {
        this.loadingCnt = 0
        return
      }

      if (this.loadingCnt + payload < 0) {
        return
      }

      this.loadingCnt = this.loadingCnt + payload
    },
    // 향후 로딩바가 커스터마이징 되었을 시 인스턴스를 받아 저장
    // 그 후 프로세스는 공통 개발자가 원하는 로직으로 설계
    setInstance(payload) {
      if (payload) {
        this.instance = payload
      } else {
        this.instance = undefined
      }
    }
  }
}

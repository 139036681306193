<template>
  <div class="ui-media-wrap">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default {
  name: 'VideoPlayer',
  props: {
    video: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      player: null,
      title: '',
      options: {
        fill: true,
        autoplay: true,
        controls: true,
        playsinline: true, // 아이폰 인터넷 재생
        muted: true, //자동재생시 초기값 필수
        sources: [
          {
            src: '',
            type: 'video/mp4'
          }
        ]
      }
    }
  },
  mounted() {
    this.title = this.video.title
    this.options.sources[0].src = this.video.contents

    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      const titleDiv = videojs.dom.createEl('div', {
        className: 'video-title',
        innerHTML: this.title
      })
      this.player.getChild('controlBar').el().prepend(titleDiv)
    })
    this.player.ready(() => {
      this.player.play()
    })
  },
  unmount() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>
